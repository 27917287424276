import React from "react";
import Layout from "../components/Layout";
export default function NotFound() {
    return (
        <Layout>
            <div style={{marginTop: "6em", textAlign: "center"}}>
                <h1>Seite nicht gefunden</h1>
                <p>Ups, wir konnten diese Seite nicht finden!</p>
            </div>
        </Layout>
    )
}
